<template>
 <form class="main-body">
  <h3 v-if="editMode == 'add'" class="subheading" >Add new category</h3>
  <h3 v-if="editMode == 'edit'" class="subheading" >Edit category</h3>

  <section class="section-container">
   
    <v-layout row>
      <v-flex mr-5 class="half">
        <label class="label" for="name">Category Name</label>
        <v-text-field
          id="name"
          v-model="categoryName"
          solo
          flat
          required
        ></v-text-field>
      </v-flex>
      <v-flex mt-4 class="half">
        <p><i>This is the name of the category that will display on the page.</i></p>
      </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="nameLink"><span v-if="modules.default.LFCExclusive">Custom </span>Category Link</label>
          <v-text-field
            id="nameLink"
            v-model="categoryLink"
            solo
            flat
            placeholder="/videos/browse?category=YourCategoryName"
            required
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p v-if="!modules.default.LFCExclusive"><i>This has to follow a structure that begins with how your video pages is called, such as "video" or "videos", and ends with the category id from your video platform. Example: /videos/browse?category=first team. Spaces are acceptable in the category name.</i></p>
          <p v-else><i>By default your categories will link to the browse page, filtered by the category name as provided above. If you enter a link here it will override that.</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
         <ImageUploader :title="'Category image'" :widthOptionEnabled="false" :mediaLibraryID="mediaLibraryID" @update="updateImage($event)"/>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>The background image that will display in the category card.</i></p>
        </v-flex>
      </v-layout>

       <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="titleVisibility">Title Visibility</label>
            <v-select
              v-model="titleVisibility"
              solo
              flat
              id="titleVisibility"
              item-text='name'
              item-value='value'
              :items="[
                { name: 'Show', value: 'show' },
                { name: 'Hide', value: 'hide' }
              ]" />
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>Shows the title on top of the image or hides it.</i></p>
        </v-flex>
      </v-layout>
    </section>

    <StickyFooter :errorMessage="null" :status="status" :buttonText="'Save Settings'" @buttonClicked="editMode == 'add' ? addCategory() : editCategory()" />

  </form>
</template>

<script>
import axios from "axios"
import ImageUploader from "@/components/Library/ImageUploader"
import StickyFooter from "@/components/Library/StickyFooter"

export default {
  name: "EditCategories",

  components: {
    ImageUploader,
    StickyFooter
  },

  data: () => ({
    api: process.env.VUE_APP_VIDEOS_API,
    categoryID: null,
    editMode: null,
    errorMessage: null,
    status: null,
    categoryName: "",
    categoryLink: "",
    categoryImage: "",
    titleVisibility: "",
    mediaLibraryID: null,
    modules: null,
  }),

  created() {
    this.modules = require("../../../../config/features/"+process.env.VUE_APP_CLUB_ID+"/modules.js")

    if (this.$route.path == "/video-management/config/categories/create") {
      this.editMode = "add"
    } else {
      this.editMode = "edit"
      this.categoryID = this.$route.params.id;
      this.fetchCategoryData()
    }
  },

  methods: {
    addCategory() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}/categories`,
          headers: { "Authorization": jwt },
          data: {
            data: {
              type: "video-category",
              attributes: {
                name: this.categoryName,
                link: `${this.categoryLink}`,
                titleVisibility: this.titleVisibility,
                images: {
                  portrait: {
                    mediaLibraryID: this.mediaLibraryID,
                    imageKey: this.categoryImage
                  }
                }
              }
            }
          }
        })
        .then(response => {
          console.log("saved settings:", response);
          this.status = "saved"
          this.$router.push({ path: "/video-management/config"})
        })
        .catch(e => {
          console.error("Problem saving CATEGORIES settings", e)
          this.status = "needsSaving"
          this.errorMessage = "Problem saving CATEGORIES settings"
        });
      })
    },

    editCategory() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "PATCH",
          url: `${this.api}/categories/${this.categoryID}`,
          headers: { "Authorization": jwt },
          data: {
            data: {
              type: "video-category",
              attributes: {
                name: this.categoryName,
                link: `${this.categoryLink}`,
                titleVisibility: this.titleVisibility,
                images: {
                  portrait: {
                    mediaLibraryID: this.mediaLibraryID,
                    imageKey: this.categoryImage
                  }
                }
              }
            }
          }
        })
        .then(response => {
          console.log("saved settings:", response);
          this.status = "saved"
          this.$router.push({ path: "/video-management/config"})
        })
        .catch(e => {
          console.error("Problem saving CATEGORIES settings", e)
          this.status = "needsSaving"
          this.errorMessage = "Problem saving CATEGORIES settings"
        });
      })
    },

    fetchCategoryData() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.api}/categories/${this.categoryID}`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          console.log("retrieved CATEGORIES settings:", response.data.data);
          this.categoryName = response.data.data.attributes.name
          this.categoryLink = response.data.data.attributes.link
          this.titleVisibility = response.data.data.attributes.titleVisibility
          if (response.data.data.attributes.images) {
            if (response.data.data.attributes.images.portrait) {
              this.categoryImage = response.data.data.attributes.images.portrait.imageKey
              this.mediaLibraryID = response.data.data.attributes.images.portrait.mediaLibraryID
            }
          }
        })
        .catch(e => {
          console.error("Problem getting CATEGORIES settings", e);
          this.status = "needsSaving"
        });
      })
    },

    updateImage(payload) {
      this.categoryImage = payload.imageKey,
      this.mediaLibraryID = payload.mediaLibraryID
    }
  }
}
</script>

<style scoped lang='scss'>
  .half {
    width: 50%;
  }
</style>
